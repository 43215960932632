import styled from "styled-components"

import { Skeleton, SkeletonTypeMap } from "@material-ui/lab"

import { colors } from "src/ui/colors"

type Props = {
  inline?: boolean
} & SkeletonTypeMap["props"]

export function MSkeleton({ inline, ...rest }: Props) {
  return <StyledSkeleton $inline={inline} {...rest}></StyledSkeleton>
}

const StyledSkeleton = styled(Skeleton)<{ $inline?: boolean }>`
  ${(props) => props.$inline && "display: inline-block"};
  background: ${colors.backgroundGrayV2};
`
